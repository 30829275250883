<template>
  <div>
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12">
        <a-card>
          <template slot="title">
            <strong>Setoran Deposito</strong>
            <a-button
              class="btn btn-outline-success ml-4"
              @click="getAllData(true)"
              >Refresh Data</a-button
            >
            <a-button
              class="btn btn-outline-success pull-right"
              @click="exportExcel"
              >Export Data</a-button
            >
            <!-- <a-button class="btn btn-secondary" @click="formvisible = !formvisible">Check</a-button> -->
          </template>
          <!-- <div class="row pl-3 pr-3 pt-3 pb-3"> -->
          <!-- <div class="col-xs-3 col-sm-3 col-md-3 col-lg-3">
              <label></label>
            </div> -->
          <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12"> -->
          <a-table
            :columns="table.tablebawah.column"
            :dataSource="table.tablebawah.datatable"
            :pagination="false"
            size="small"
            :customRow="customRowB"
          >
            <div
              slot="filterDropdown"
              slot-scope="{
                setSelectedKeys,
                selectedKeys,
                confirm,
                clearFilters,
                column,
              }"
              style="padding: 8px;"
            >
              <a-input
                v-ant-ref="(c) => (searchInput = c)"
                :placeholder="`Search ${column.dataIndex}`"
                :value="selectedKeys[0]"
                style="width: 188px; margin-bottom: 8px; display: block;"
                @change="
                  (e) => setSelectedKeys(e.target.value ? [e.target.value] : [])
                "
                @pressEnter="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              />
              <a-button
                type="primary"
                icon="search"
                size="small"
                style="width: 90px; margin-right: 8px;"
                @click="
                  () => handleSearch(selectedKeys, confirm, column.dataIndex)
                "
              >
                Search
              </a-button>
              <a-button
                size="small"
                style="width: 90px;"
                @click="() => handleReset(clearFilters)"
              >
                Reset
              </a-button>
            </div>
            <a-icon
              slot="filterIcon"
              slot-scope="filtered"
              type="search"
              :style="{ color: filtered ? '#108ee9' : undefined }"
            ></a-icon>
            <template
              slot="customRender"
              slot-scope="text, record, index, column"
            >
              <span v-if="searchText && searchedColumn === column.dataIndex">
                <template
                  v-for="(fragment, i) in text
                    .toString()
                    .split(
                      new RegExp(`(?<=${searchText})|(?=${searchText})`, 'i')
                    )"
                >
                  <mark
                    v-if="fragment.toLowerCase() === searchText.toLowerCase()"
                    :key="i"
                    class="highlight"
                    >{{ fragment }}</mark
                  >
                  <template v-else>{{ fragment }}</template>
                </template>
              </span>
              <template v-else>
                {{ text }}
              </template>
            </template>
            <span slot="tgl" slot-scope="text">
              {{ formatDate(text) }}
            </span>
            <span slot="sudah_cair" slot-scope="text">
              {{ text ? "Sudah Cair" : "Belum Cair" }}
            </span>
            <span slot="sudah_cair" slot-scope="text">
              {{ text ? "Sudah Cair" : "Belum Cair" }}
            </span>
            <span slot="nominal" slot-scope="text">
              {{ formatCurrency(text) }}
            </span>
          </a-table>
          <!-- </div>
          </div> -->
        </a-card>
      </div>
    </div>
    <a-modal v-model="formvisible" :width="900" title="Setoran Deposito">
      <template slot="footer">
        <a-button class="btn btn-secondary" @click="formvisible = !formvisible"
          >Cancel</a-button
        >
        <a-button class="btn btn-primary" @click="handleSubmit"
          >Submit</a-button
        >
      </template>
      <div class="row">
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <div class="row">
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4">
              <label class="mt-1">Rekening</label>
            </div>
            <div class="col-lg-8 col-md-8 col-sm-8">
              <a-input
                style="width: 50%;"
                placeholder="Rekening"
                v-model="maskInput.rekening"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
              <label class="mt-1">Tgl Transaksi</label>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-input
                style="width: 100px;"
                :readOnly="true"
                v-model="maskInput.tgl"
              ></a-input>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
              <label class="mt-1">Nama Nasabah</label>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-input
                style=""
                :readOnly="true"
                v-model="maskInput.nama"
              ></a-input>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
              <label class="mt-1">Alamat Nasabah</label>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-input
                style=""
                :readOnly="true"
                v-model="maskInput.nama"
              ></a-input>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
              <label style="font-size: 13px;" class="mt-1"
                >Golongan Deposito</label
              >
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-input
                style=""
                :readOnly="true"
                v-model="maskInput.golongan_deposito"
              ></a-input>
            </div>
            <!-- <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
              <label class="mt-1">Jangka Waktu</label>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-input
                :readOnly="true"
                style="width: 70px; text-align: right;"
                class="mr-2"
              ></a-input>
              Bulan
            </div> -->
          </div>
        </div>
        <div class="col-xs-6 col-sm-6 col-md-6 col-lg-6">
          <div class="row">
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
              <label style="font-size: 13px;" class="mt-1"
                >Tanggal Jth Tempo</label
              >
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-input
                style="width: 130px;"
                v-model="input.tgl"
                :readOnly="true"
              ></a-input>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
              <label class="mt-1">Rek. Tabungan</label>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-input
                style="width: 150px;"
                :readOnly="true"
                v-model="maskInput.rekening_tabungan"
              ></a-input>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
              <label class="mt-1">Saldo Tabungan</label>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-input
                style="width: 100px;"
                :readOnly="true"
                v-model="maskInput.rekening_tabungan"
              ></a-input>
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
              <label class="mt-1">Nominal</label>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <cleave
                :options="{ numeral: true }"
                class="ant-input mr-1"
                :raw="true"
                v-model="input.nominal"
                style="width: 100px; text-align: right;"
                placeholder="0.00"
              />
            </div>
            <div class="col-xs-4 col-sm-4 col-md-4 col-lg-4 mt-1">
              <label style="font-size: 13px;" class="mt-1">Cara Setoran</label>
            </div>
            <div class="col-xs-8 col-sm-8 col-md-8 col-lg-8 mt-1">
              <a-select
                show-search
                option-filter-prop="children"
                :filter-option="filterOption"
                v-model="input.cara_penyetoran"
                style="width: 100%;"
              >
                <a-select-option :value="1">Tunai</a-select-option>
                <a-select-option :value="2">Tabungan</a-select-option>
                <a-select-option :value="3">PB</a-select-option>
              </a-select>
            </div>
            <!-- <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 mt-2">
              <a-button
                size="default"
                type="primary"
                class="ml-2 mr-5 float-right"
                @click="handleSubmit"
                >Submit</a-button
              >
            </div> -->
          </div>
        </div>
      </div>
    </a-modal>
  </div>
</template>

<script>
import { Modal } from 'ant-design-vue'
import moment from 'moment'
import * as lou from '@/services/data/lou'
import { mapState } from 'vuex'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    cleave: Cleave,
  },
  computed: mapState(['settings', 'user']),
  watch: {
    // 'settings.selectedRekening'() {
    //   this.checkRekening()
    // },
  },
  data() {
    return {
      searchText: '',
      searchInput: null,
      searchedColumn: '',
      mastergolonganDeposito: [],
      backupmastergolonganDeposito: [],
      input: {
        id: 0,
        rekening: '',
        tgl: '',
        nominal: '',
        cara_penyetoran: '',
      },
      maskInput: {
        rekcabang: '01',
        rerekening: '',
        rerekening1: '',
        tgl: '',
        nama: '',
        alamat: '',
        golongan_deposito: '',
        jw: '',
        rekening_tabungan: '',
        saldo_tabungan: '',
      },
      allMaster: {
        carapencairan: [],
      },

      // Modal Property
      formvisible: false,
      carinasabahtitle: 'Cari',
      inputModal: {
        nama: '',
        alamat: '',
        cabang: '',
      },
      table: {
        tableatas: {
          column: [
            {
              title: 'Kode',
              rowKey: 'kode',
              dataIndex: 'kode',
              scopedSlots: { customRender: 'kode' },
              width: 100,
            },
            {
              title: 'Nama',
              dataIndex: 'nama',
              scopedSlots: { customRender: 'nama' },
              width: 150,
            },
            // {
            //   title: 'KTP',
            //   dataIndex: 'ktp',
            //   scopedSlots: { customRender: 'ktp' },
            //   width: 100,
            // },
            {
              title: 'Alamat',
              dataIndex: 'alamat',
              scopedSlots: { customRender: 'alamat' },
              // width: 100,
            },
          ],
          datatable: [],
        },
        tablebawah: {
          column: [
            {
              title: 'Tanggal',
              width: 100,
              dataIndex: 'tgl',
              scopedSlots: { customRender: 'tgl' },
              // width: 100,
            },
            {
              title: 'Rekening',
              dataIndex: 'rekening',

              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'customRender',
              },
              onFilter: (value, record) =>
                record.rekening
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()),
              onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
              // width: 100,
            },
            {
              title: 'Nama',
              dataIndex: 'nama',

              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'customRender',
              },
              onFilter: (value, record) =>
                record.nama
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()),
              onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
              // width: 100,
            },
            {
              title: 'Rekening Tabungan',
              dataIndex: 'rekening_tabungan',

              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'customRender',
              },
              onFilter: (value, record) =>
                record.rekening_tabungan
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()),
              onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
              // width: 100,
            },
            {
              title: 'Nama Tabungan',
              dataIndex: 'nama_tabungan',
              scopedSlots: {
                filterDropdown: 'filterDropdown',
                filterIcon: 'filterIcon',
                customRender: 'customRender',
              },
              onFilter: (value, record) =>
                record.nama_tabungan
                  .toString()
                  .toLowerCase()
                  .includes(value.toLowerCase()),
              onFilterDropdownVisibleChange: (visible) => {
                if (visible) {
                  setTimeout(() => {
                    this.searchInput.focus()
                  }, 0)
                }
              },
              // width: 100,
            },
            {
              title: 'Golongan Deposito',
              dataIndex: 'nama_golongandeposito',

              scopedSlots: { customRender: '' },
            },
            {
              title: 'Nama AO',
              dataIndex: 'nama_ao',

              scopedSlots: { customRender: '' },
            },
            {
              title: 'Jth Tempo',
              dataIndex: 'jthtmp',

              scopedSlots: { customRender: 'tgl' },
            },
            {
              title: 'Username',
              dataIndex: 'username',

              scopedSlots: { customRender: '' },
            },
          ],
          datatable: [],
        },
      },
    }
  },
  created() {
    this.getAllData()
  },
  methods: {
    moment,
    async showModal() {
      this.carinasabahvisible = true
      var res = await lou.customUrlGet2('deposito_setoran')
      if (res) {
        this.table.tablebawah.datatable = res.data
      }
    },
    handleSubmit() {
      Modal.confirm({
        title: 'Apakah anda yakin data sudah benar?',
        content: 'Data tidak dapat di edit ataupun di hapus nanti!',
        okText: 'Yes',
        okType: 'danger',
        cancelText: 'No',
        onOk: async () => {
          var res = await lou.customUrlPost2('deposito_setoran', {
            id: this.input.id,
            nominal: this.input.nominal,
            cara_penyetoran: this.input.cara_penyetoran,
          })
          if (res) {
            this.formvisible = false
            this.getAllData()
          }
        },
        onCancel: () => {
          // console.log('Cancel')
          return false
        },
      })
    },
    async checkRekening() {
      this.input.rekening = this.settings.selectedRekening
      var text = this.settings.selectedRekening
      this.maskInput.rekcabang = text.slice(0, text.indexOf('.'))
      this.maskInput.rerekening = text.slice(
        text.indexOf('.') + 1,
        text.indexOf('.') + 3,
      )
      var ntext = text.replace('.', '')
      // console.log('ntext', ntext.slice(ntext.indexOf('.') + 1, ntext.length))
      this.maskInput.rerekening1 = ntext.slice(
        ntext.indexOf('.') + 1,
        ntext.length,
      )
      // this.rekeningcheck()
    },
    async rekeningcheck() {
      var res = await lou.customUrlGet2(
        'data_kredit/rekening/' +
          this.maskInput.rekcabang +
          '.' +
          this.maskInput.rerekening +
          '.' +
          this.maskInput.rerekening1,
      )
      if (res) {
        if (res.data !== null) {
          lou.shownotif('Ditemukan!', 'Rekening ditemukan.')
        } else {
          lou.shownotif(
            'Tidak ditemukan!',
            'Rekening tidak ditemukan.',
            'error',
          )
        }
      }
    },
    async getAllData(onclick = false) {
      var indexeddb = await lou.getDataFromDb()
      // console.log('indexeddb', indexeddb)
      // console.log('this.settings.whatapp', this.settings.whatapp)
      var allmaster = {}
      var masterIndex = indexeddb.findIndex(
        (x) =>
          x.mykey ===
          'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
      )
      // console.log('masterIndex', indexeddb[masterIndex])
      if (masterIndex < 0) {
        var res1 = await lou.customUrlGet2('master', false, true)
        var master = {
          mykey:
            'allmaster' +
            this.settings.whatapp.appName.toLowerCase() +
            this.settings.whatapp.id,
          data: JSON.stringify(res1.data),
        }
        await lou.addDataToDb(master)
        var nindexeddb = await lou.getDataFromDb()
        var targetIndex = nindexeddb.findIndex(
          (x) =>
            x.mykey ===
            'allmaster' +
              this.settings.whatapp.appName.toLowerCase() +
              this.settings.whatapp.id,
        )
        // console.log('nindexeddb', nindexeddb[targetIndex])
        allmaster = JSON.parse(nindexeddb[targetIndex].data)
      } else {
        allmaster = JSON.parse(indexeddb[masterIndex].data)
      }
      var cabanglist = []
      for (let u = 0; u < this.user.cabangList.length; u++) {
        const element = this.user.cabangList[u]
        // console.log('element', element)
        cabanglist.push({ kode: element.kode, keterangan: element.kode })
      }
      // console.log('indexeddb', indexeddb)
      this.allMaster = allmaster
      // console.log('this.allMaster', this.allMaster)
      var res = await lou.customUrlGet2('deposito_setoran')
      if (res) {
        this.table.tablebawah.datatable = res.data
        if (onclick) {
          lou.shownotif('Refresh', 'Data telah di refresh.')
        }
      }
    },
    // Modal function
    clearRekening() {
      this.settings.selectedRekening = ''
    },
    async findData() {
      var imbuhan = ''
      imbuhan +=
        this.inputModal.nama !== '' ? '?Nama=' + this.inputModal.nama : ''
      imbuhan +=
        this.inputModal.alamat !== ''
          ? this.inputModal.nama !== ''
            ? '&Alamat=' + this.inputModal.alamat
            : '?Alamat=' + this.inputModal.alamat
          : ''
      imbuhan +=
        this.inputModal.cabang !== ''
          ? '&cabang=' + this.inputModal.cabang
          : ''
      var res = await lou.customUrlGet2('carinasabah' + imbuhan)
      if (res) {
        this.table.tableatas.datatable = res.data
        // for (let i = 0; i < 20; i++) {
        //   const element = res.data[0]
        //   this.table.tableatas.datatable.push(element)
        // }
      }
    },
    async nasabahDetail(record) {
      this.loading = true
      var res = await lou.customUrlGet2('carinasabah/detail/' + record.kode)
      if (res) {
        this.maskInput.cabang = record.kode.slice(0, record.kode.indexOf('.'))
        this.maskInput.reregister = record.kode.slice(
          record.kode.indexOf('.') + 1,
          record.kode.length,
        )
        // this.registeridchanged()
        this.table.tablebawah.datatable = res.data
        this.loading = false
      }
    },
    customRow(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)
            // console.log('index', index)
            // console.log('event', event)
            this.settings.selectedKodeRegister = record.kode
            this.nasabahDetail(record)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    customRowB(record, index) {
      return {
        on: {
          click: (event) => {
            // console.log('record', record)

            this.input.id = record.id
            this.input.tgl = moment(record.jthtmp).format('DD-MM-YYYY')
            this.maskInput.tgl = moment(record.tgl).format('DD-MM-YYYY')
            var text = record.rekening
            this.maskInput.rekcabang = text.slice(0, text.indexOf('.'))
            this.maskInput.rerekening = text.slice(
              text.indexOf('.') + 1,
              text.indexOf('.') + 3,
            )
            var ntext = text.replace('.', '')
            // console.log('ntext', ntext.slice(ntext.indexOf('.') + 1, ntext.length))
            this.maskInput.rerekening1 = ntext.slice(
              ntext.indexOf('.') + 1,
              ntext.length,
            )
            this.maskInput = this.$g.clone(record)
            this.maskInput.golongan_deposito = record.nama_golongandeposito
            this.maskInput.plafond = this.formatCurrency(record.plafond)
            this.maskInput.biaya_administrasi = this.formatCurrency(
              record.biaya_administrasi,
            )
            this.maskInput.biaya_asuransi = this.formatCurrency(
              record.biaya_asuransi,
            )
            this.maskInput.biaya_materai = this.formatCurrency(
              record.biaya_materai,
            )
            this.maskInput.biaya_notaris = this.formatCurrency(
              record.biaya_notaris,
            )
            // console.log('this.maskInput', this.maskInput)
            this.formvisible = true
            // this.settings.selectedRekening = record.rekening
            // console.log('index', index)
            // console.log('event', event)
            // console.log('this.settings.selectedRekening', this.settings.selectedRekening)
          },
          contextmenu: (event) => {
            event.preventDefault()
          },
        },
      }
    },
    formatDate(value) {
      return moment(value).format('DD-MM-YYYY')
    },
    formatCurrency(v) {
      return lou.curency(v)
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      )
    },
    dateFormatter(params) {
      var ret =
        params.value === null
          ? ''
          : moment(params.value, 'YYYY-MM-DD').format('DD-MM-YYYY')
      // console.log('dateFormatter', ret)
      return ret !== 'Invalid date' ? ret : params.value
    },
    currencyFormatter(params) {
      var ret =
        params.value === null
          ? 'NaN'
          : lou.curency(parseFloat(params.value).toFixed(0))
      return ret === 'NaN' ? '' : ret
    },
    handleSearch(selectedKeys, confirm, dataIndex) {
      confirm()
      this.searchText = selectedKeys[0]
      this.searchedColumn = dataIndex
    },

    handleReset(clearFilters) {
      clearFilters()
      this.searchText = ''
    },
    async exportExcel() {
      const ExcelJS = require('exceljs')
      const workbook = new ExcelJS.Workbook()
      workbook.creator = 'LB-BPRS Heasoft System'
      workbook.lastModifiedBy = 'Me'
      workbook.created = new Date()
      workbook.modified = new Date()
      workbook.lastPrinted = new Date()
      var alphabeth = ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z']
      const worksheet = workbook.addWorksheet(moment().format('DD-MM-YYYY'))

      // Table Data
      var column = this.$g.clone(this.table.tablebawah.column)
      var tdata = this.$g.clone(this.table.tablebawah.datatable)
      var data = []
      tdata.forEach(element => {
        var retv = {
          ...element,
          tgl: element.tgl === null ? '' : moment(element.tgl, 'YYYY-MM-DD').format('DD-MM-YYYY'),
          jthtempo: element.jthtempo === null ? '' : moment(element.jthtempo, 'YYYY-MM-DD').format('DD-MM-YYYY'),
        }
        data.push(retv)
      })
      var cfirst = 0
      var cloop = 0
      var dincrement = 2
      var columnLocation = []
      for (let i = 0; i < column.length; i++) {
        const element = column[i]
        var cloopni = cloop - 1
        var hname = element.title.toString()
        if (hname !== 'Action') {
          if (cloop === 0) {
            columnLocation.push({
              location: alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
          } else {
            columnLocation.push({
              location: alphabeth[cloopni] + alphabeth[cfirst],
              field: element.dataIndex,
            })
            worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + '1').value = hname.replaceAll(' ', '')
          }
          for (let u = 0; u < data.length; u++) {
            const el = data[u]
            if (cloop === 0) {
              worksheet.getCell(alphabeth[cfirst] + dincrement).value = el[element.dataIndex]
            } else {
              worksheet.getCell(alphabeth[cloopni] + alphabeth[cfirst] + dincrement).value = el[element.dataIndex]
            }
            var checklast = u + 1
            if (checklast === data.length) {
              dincrement = 2
            } else {
              dincrement += 1
            }
          }
          if (cfirst === 25) {
            cfirst = 0
            cloop += 1
          } else {
            cfirst += 1
          }
        }
      }

      /* For sum data */
      // var last = data.length + 3
      // worksheet.mergeCells('A' + last + ':B' + last)
      // worksheet.getCell('A' + last).value = 'Total'
      // for (let ti = 0; ti < this.columnsTotal.length; ti++) {
      //   const element = this.columnsTotal[ti]
      //   var targetLoc = columnLocation.findIndex(x => x.field === element.dataIndex)
      //   if (targetLoc >= 0) {
      //     worksheet.getCell(columnLocation[targetLoc].location + last).value = this.rowDataTotal[0][element.dataIndex]
      //   }
      //   // console.log(columnLocation[targetLoc].location + last, this.rowDataTotal[0][element.dataIndex])
      // }

      // write to a new buffer
      const buffer = await workbook.xlsx.writeBuffer()
      var blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' })
      var link = document.createElement('a')
      link.href = window.URL.createObjectURL(blob)
      link.download = 'Data Setoran Deposito.xlsx'
      link.click()
    },
  },
}
</script>

<style lang="scss" scoped></style>
